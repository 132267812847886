var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.handleView),expression:"handleView",modifiers:{"once":true}}],staticClass:"proposal-custom-image",style:(_vm.cardStyle),attrs:{"href":_vm.link},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_c('v-img',{attrs:{"src":_vm.imgSrc,"alt":_vm.proposal.imgAlt,"title":_vm.proposal.imgDescription,"eager":_vm.eager}},[(
        _vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.SHOW_BLACK_LAYER'
        )
      )?_c('div',{staticClass:"blurred-bg"}):_vm._e(),_c('div',{staticClass:"text-overlay",class:_vm.$ebsn.meta(
          _vm.proposal,
          'metaData.category_proposal_type.TEXT_ALIGN',
          ' text-align-center '
        ) +
          ' ' +
          _vm.$ebsn.meta(
            _vm.proposal,
            'metaData.category_proposal_type.TEXT_ALIGN_HORIZONTAL',
            ' text-align-center '
          ) +
          ' ' +
          _vm.$ebsn.meta(
            _vm.proposal,
            'metaData.category_proposal_type.TEXT_ALIGN_VERTICAL',
            ' '
          )},[(_vm.proposal.recipe_cook_time || _vm.proposal.rating)?_c('div',{staticClass:"recipe-content d-flex px-4"},[(_vm.proposal.recipe_cook_time)?_c('div',{staticClass:"chip-info"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("$schedule")]),_c('span',{staticClass:"px-1"},[_vm._v(" "+_vm._s(_vm.proposal.recipe_cook_time)+" "+_vm._s(_vm.proposal.recipe_cook_time_text)+" ")])],1):_vm._e(),(_vm.proposal.rating)?_c('div',{staticClass:"chip-info"},[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"black"}},[_vm._v("$signal")]),_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm.proposal.rating))])],1):_vm._e()]):_vm._e(),(_vm.proposal.descr)?_c('v-card-title',{staticClass:"pt-1",domProps:{"innerHTML":_vm._s(_vm.proposal.descr)}}):_vm._e(),(_vm.proposal.content)?_c('v-card-text',{staticClass:"pb-1",domProps:{"innerHTML":_vm._s(_vm.proposal.content)}}):_vm._e()],1),_c('div',{staticClass:"actions-overlay"},[(
          _vm.$ebsn.meta(_vm.proposal, 'metaData.category_proposal_type.BTN_TEXT')
        )?_c('v-card-actions',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"href":_vm.link,"outlined":_vm.$ebsn.meta(
              _vm.proposal,
              'metaData.category_proposal_type.BTN_OUTLINED',
              false
            ),"color":_vm.$ebsn.meta(
              _vm.proposal,
              'metaData.category_proposal_type.BTN_COLOR',
              'primary'
            )}},[_vm._v(_vm._s(_vm.$ebsn.meta(_vm.proposal, "metaData.category_proposal_type.BTN_TEXT")))])],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }