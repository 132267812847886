<template>
  <v-card flat class="footer-card primary mx-4">
    <v-card-title>
      {{ proposal.name }}
    </v-card-title>
    <v-card-subtitle>
      <div v-html="proposal.content"></div>
    </v-card-subtitle>
    <v-img :src="proposal.img" :alt="proposal.imgAlt" class="mt-2">
      <v-card-actions class="d-flex justify-center my-3 pb-5">
        <v-btn
          depressed
          :class="proposal.metaData.category_proposal_type.BTN_COLOR"
          :to="proposal.link"
        >
          {{ proposal.metaData.category_proposal_type.BTN_TEXT }}
        </v-btn>
      </v-card-actions>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.footer-card.v-card {
  border-radius: $border-radius-root;
  height: auto;

  .v-card__title {
    font-size: 1.5rem !important;
    line-height: 2rem;
    font-weight: bold !important;
    color: $white;
  }
  .v-card__subtitle {
    font-size: 1.1rem;
    height: 110px;
    color: $white;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-card__subtitle {
      font-size: 1.1rem;
      height: 150px;
      color: $white;
    }
  }
  .v-card__actions {
    align-items: flex-end;
    height: 100%;
  }
}
</style>
<script>
export default {
  name: "FooterCard",
  props: {
    proposal: { type: Object, required: true }
  },
  mounted() {
    if (
      this.proposal.img.indexOf("https://dev-grosmarket.digitelematica.org") >
      -1
    ) {
      this.proposal.img =
        "https://www.grosmarket.it" + this.proposal.img.split("org")[1];
    }
  }
};
</script>
